import React from 'react'
import ReactDOM from 'react-dom'
import {ScrollToTop} from './components'
import { BrowserRouter as Router } from 'react-router-dom'
import "./components/i18n"
import reportWebVitals from './reportWebVitals'

import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App'
import './scss/app.scss'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
